<template>
  <div class="members">
    <div class="members__container">
      <div
        class="members__content col-11 col-sm-10"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="300"
      >
        <h2>{{ title }}</h2>
      </div>
      <div
        id="app"
        class="col-11 col-sm-10 m-auto d-none d-sm-block"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="300"
      >
        <div class="members__firstpenguin--container">
          <div class="tab-header">
            <!-- <button
              v-for="(tab, index) in cards"
              :key="index"
              :class="{ active: activeTab === index }"
              @click="changeTab(index)"
            >
              <div class="members__firstpenguin__item">
                <img v-lazy="tab.avatar" v-bind:style="{}" alt="avatar" />
              </div>
            </button> -->

            <Flicking
              ref="flicking"
              :options="{
                circular: true,
                align: isLimitSlide ? 'prev' : 'center',
                preventDefaultOnDrag: true,
                defaultIndex: 2,
                inputType: [],
                disableOnInit: true,
              }"
              :plugins="plugins"
            >
              <button
                v-for="(tab, index) in cards"
                :key="index"
                :class="{ active: activeTab === index }"
                @click="changeTab(index)"
                class="members__firstpenguin__item"
              >
                <!-- <div class="members__firstpenguin__item"> -->
                <img :src="tab.avatar" v-bind:style="{}" alt="avatar" />
                <!-- </div> -->
              </button>

              <span
                slot="viewport"
                class="flicking-arrow-prev"
                :style="{
                  display: 'none',
                }"
              ></span>
              <span
                slot="viewport"
                class="flicking-arrow-next"
                :style="{
                  display: 'none',
                }"
              ></span>
            </Flicking>
          </div>
          <div class="tab-content">
            <div
              v-for="(tab, index) in cards"
              :key="index"
              v-show="activeTab === index"
            >
              <div class="members__firstpenguin__content">
                <h5>{{ tab.name }}</h5>
                <p>{{ tab.position }}</p>
                <div class="members__firstpenguin__content--line"></div>
                <p>{{ tab.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="slider-firstpenguin d-block d-sm-none col-11 col-sm-10 m-auto"
      >
        <div class="carousel">
          <div class="carousel-wrapper">
            <transition-group name="carousel-slide" tag="div">
              <div :key="0" class="carousel-item">
                <img
                  v-lazy="cards[activeIndex].avatar"
                  v-bind:style="{}"
                  alt="avatar"
                />
                <div class="members__firstpenguin__content">
                  <h5>{{ cards[activeIndex].name }}</h5>
                  <p>{{ cards[activeIndex].position }}</p>
                  <div class="members__firstpenguin__content--line"></div>
                  <p>{{ cards[activeIndex].content }}</p>
                </div>
              </div>
            </transition-group>
          </div>
          <div class="carousel-controls">
            <button @click="prevItem">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.667 7C19.2193 7 19.667 7.44772 19.667 8C19.667 8.55228 19.2193 9 18.667 9V7ZM0.987663 8.70711C0.597139 8.31658 0.597139 7.68342 0.987663 7.29289L7.35162 0.928932C7.74215 0.538408 8.37531 0.538408 8.76584 0.928932C9.15636 1.31946 9.15636 1.95262 8.76584 2.34315L3.10898 8L8.76584 13.6569C9.15636 14.0474 9.15636 14.6805 8.76584 15.0711C8.37531 15.4616 7.74215 15.4616 7.35162 15.0711L0.987663 8.70711ZM18.667 9H1.69477V7H18.667V9Z"
                  fill="#002A9C"
                />
              </svg>
            </button>
            <button @click="nextItem">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="services__item__detail-img"
              >
                <path
                  d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                  fill="#002A9C"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        class="welcomeProject"
        :class="{ addPaddingTienCTO: shouldAddClass }"
      >
        <Welcome :data="welcome" />
      </div>
    </div>
  </div>
</template>

<script>
import Welcome from '../Components/Welcome.vue';
import { mapState } from 'vuex';
import { Arrow } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';

export default {
  name: 'OurFirstPenguin',
  data() {
    return {
      tab: 2,
      title: this.$t('about_us').ourFirstPenguin.title,
      sub: this.$t('about_us').ourFirstPenguin.sub,
      cards: [
        ...this.$t('about_us').ourFirstPenguin.cards,
        ...this.$t('about_us').ourFirstPenguin.cards,
      ],
      data: [],
      welcome: this.$t('about_us').welcome,
      activeTab: 2,
      contentHeight: 0,
      // Carousel
      activeIndex: 2,
      items: ['Item 1', 'Item 2'],
      isLimitSlide: false,
      plugins: [new Arrow()],
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
      this.$refs.flicking.moveTo(index);
      this.contentHeight =
        this.contentHeight === 0 ? this.$refs.content?.scrollHeight : 0;
    },
    prevItem() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.cards.length - 1;
      }
    },
    nextItem() {
      if (this.activeIndex < this.cards.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
  },
  mounted() {
    this.data = this.data.map((item, indx) => ({
      title: this.cards[indx].title,
      name: this.cards[indx].name,
      position: this.cards[indx].position,
      content: this.cards[indx].content,
      avatar: this.cards[indx].avatar,
    }));
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').ourFirstPenguin.title;
      this.sub = this.$t('about_us').ourFirstPenguin.sub;
      this.cards = this.$t('about_us').ourFirstPenguin.cards;
      this.data = this.data.map((item, indx) => ({
        ...item,
        title: this.cards[indx].title,
        name: this.cards[indx].name,
        position: this.cards[indx].position,
        content: this.cards[indx].content,
      }));
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
    shouldAddClass() {
      return (
        (this.activeIndex === 1 || this.activeIndex === 6) &&
        this.$i18n.locale === 'vn'
      );
    },
  },

  components: { Welcome },
};
</script>

<style lang="scss" scroped>
.welcomeProject {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.addPaddingTienCTO {
  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

.carousel {
  width: 300px;
  margin: 0 auto;
}

.carousel-wrapper {
  position: relative;
  height: 650px;
}

.carousel-slide-enter-active,
.carousel-slide-leave-active {
  transition: none;
}

.carousel-slide-enter,
.carousel-slide-leave-to {
  transform: none;
}

.carousel-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: none;
  margin: 0;
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  top: 35%;
  button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    padding: 12px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
}

.tab-header {
  display: flex;
  justify-content: space-between;
}

.tab-header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.tab-header button.active {
  font-weight: bold;
}
.tab-content {
  transition: height 0.3s ease;
  overflow: hidden;
}
.members {
  width: 100%;
  padding-top: 50px;
  background: #fff3ec;
  .members__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .members__content {
    text-align: center;
    width: 60%;
    margin: 20px auto;
    h2 {
      position: relative;
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      margin-bottom: 16px;
      z-index: 0;
    }
    p {
      color: var(--grey-text);
      font-size: 18px;
      margin: 0;
    }
  }
  .v-slide-group__content {
    align-items: flex-end;
  }
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
      .v-slide-group--has-affixes
    )
    .v-slide-group__prev {
    display: none !important;
  }
  .theme--light.v-card,
  .theme--light.v-sheet,
  .theme--light.v-application,
  .theme--light.v-tabs-items {
    background-color: transparent;
    border-color: transparent;
  }
  .v-application--wrap {
    min-height: auto;
  }
  .v-tabs-bar {
    height: auto;
    .v-tabs-slider {
      background-color: transparent;
    }
    .v-tab {
      padding: 0;
      margin: 0 -10px;
    }
    .v-tab:before {
      background-color: transparent !important;
    }
    .v-tab--active {
      img {
        filter: grayscale(0);
      }
    }
    .v-ripple__container {
      display: none !important;
    }
  }
  .v-window-item {
    width: 100%;
  }
  .members__firstpenguin--container {
    width: 100%;
    box-shadow: none !important;
    padding-top: 60px;
    position: relative;
    background: url('/images/bg/bg-first-penguin.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      background: url('/images/bg/bg-first-penguin.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    } */
  }
  .members__firstpenguin__item {
    min-width: 20%;

    height: auto;
    img {
      object-fit: contain;
      width: 15vw;
    }
  }
  .members__firstpenguin__content {
    width: 100%;
    max-width: 600px;
    height: 300px;
    padding: 30px 0;
    margin: 0 auto;
    animation: slide-up 0.3s;

    h5 {
      color: var(--blue-text);
      font-weight: 700;
    }
    p {
      white-space: pre-line;
      line-height: 1.25;
      font-size: 18px;
    }
    .members__firstpenguin__content--line {
      padding: 0;
      width: 100%;
      height: 1px;
      background-color: rgb(255, 187, 115);
      margin-bottom: 16px;
    }
  }
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @media (max-width: 1024px) {
    .members__firstpenguin__item img {
      width: 100%;
    }
    .members__content {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .members__content h2 {
      font-size: 32px;
    }
    .members__firstpenguin__item img {
      width: 100%;
      max-width: 100%;
    }
    .slider-firstpenguin {
      margin-top: 10px;
      img {
        width: 170px;
        height: auto;
      }
      .members__firstpenguin__content {
        color: var(--grey-text);
        height: 450px;
      }
    }
    .v-window__container {
      height: 700px !important;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      .v-window__prev {
        order: 2;
      }
      .v-window__next {
        order: 1;
      }
    }
    .v-btn:not(.v-btn--round).v-size--default {
      min-height: 50px;
      min-width: 50px;
    }

    .theme--dark.v-btn.v-btn--has-bg {
      background-color: var(--orange);
      border-radius: 50%;
      box-shadow: none;
      path {
        fill: #fff;
      }
    }

    .v-window__next,
    .v-window__prev {
      //position: static;
      border-radius: 0;
      margin: 0;
      //padding-right: 10px;
      /* height: fit-content; */
      overflow: hidden;
      background-color: transparent;
      transform: none !important;
      top: calc(50% - 100px);
    }
  }
}
</style>
