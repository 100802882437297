<template>
  <div>
    <Banner :data="dataBanner" />
    <Message />
    <Slogan />
    <Vision />
    <Value />
    <Rule />
    <Morale />
    <OurFirstPenguin />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OurFirstPenguin from '../components/Renew/AboutUs/OurFirstPenguin.vue';

const Banner = () => import('../components/Renew/Components/Banner.vue');
const Message = () => import('../components/Renew/AboutUs/Message.vue');
const Slogan = () => import('../components/Renew/AboutUs/Slogan.vue');
const Vision = () => import('../components/Renew/AboutUs/Vision.vue');
const Rule = () => import('../components/Renew/AboutUs/Rule.vue');
const Morale = () => import('../components/Renew/AboutUs/Morale.vue');
const Value = () => import('../components/Renew/AboutUs/Value.vue');
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'AboutNew',
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      icons: ['mdi-facebook', 'mdi-instagram', 'mdi-linkedin', 'mdi-github'],
      header: this.$t('header'),
      about_us_head: this.$t('about_us.about_us_head'),
      about_us_foot: this.$t('about_us.about_us_foot'),
      our_video: this.$t('about_us.header_video'),
      content_members: this.$t('about_us.members_bld'),
      dataBanner: {
        title: this.$t('about_us').banner.title,
        images: '/images/aboutus/bg-banner-about.webp',
        sub: this.$t('about_us').banner.sub,
      },
      seo: this.$t('seo').about_us,
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: 'description',
          content: this.seo.description,
        },
        {
          property: 'og:title',
          content: this.seo.title,
        },
        {
          property: 'og:description',
          content: this.seo.description,
        },
      ],
    };
  },
  components: {
    Banner,
    Message,
    Slogan,
    Vision,
    Rule,
    Morale,
    Value,
    Footer,
    OurFirstPenguin,
  },
  watch: {
    lang() {
      this.header = this.$t('header');
      this.about_us_head = this.$t('about_us.about_us_head');
      this.about_us_foot = this.$t('about_us.about_us_foot');
      this.our_video = this.$t('about_us.header_video');
      this.content_members = this.$t('about_us.members_bld');
      this.seo = this.$t('seo').about_us;
      this.dataBanner = {
        title: this.$t('about_us').banner.title,
        images: '/images/aboutus/bg-banner-about.webp',
        sub: this.$t('about_us').banner.sub,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
